import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  MuiPickersUtilsProvider,
  
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import PickerDateFns from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import BeatLoader from "react-spinners/BeatLoader"


import "./styles.css";





const loading = () => (
  <div className=" text-center d-flex flex-column justify-content-center align-items-center mx-auto" style={{height:400,marginTop:20,marginBottom:20}}><BeatLoader color="#067BE3" loading={true} size={12}/></div>

  );

  
  // Containers
  const DefaultLayout = Loadable({
    loader: () => import("./components/pages/defaultLayout"),
    loading
  });
  
  // Pages
  const Login = Loadable({
    loader: () => import("./components/pages/login"),
    loading
  });
  const Page404 = Loadable({
    loader: () => import("./components/pages/404"),
    loading
  });
  
 
class App extends React.Component {  
     
   render() {       
    return ( 
      <Provider store={store}>   
        <HashRouter>
        <MuiPickersUtilsProvider utils={PickerDateFns} locale={frLocale}>
        <SnackbarProvider>
         <Switch>
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
        </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </HashRouter>  
      </Provider> 
    
       
);  
} 
} 


export default App;



