
var initialState = {
  open: false,
  openExistUser: false,
  info: {}
};

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    //-------Fetch-------//
    case "OPEN":
      return { ...state, open: true, info: action.payload };
    case "CLOSE":
      return { ...state, open: false };
    case "OPEN_EXISTUSER":
      return { ...state, openExistUser: true, info: action.payload };
    case "CLOSE_EXISTUSER":
      return { ...state, openExistUser: false };
    //----DEFAULT----//
    default:
      return state;
  }
};
export default AlertReducer;
